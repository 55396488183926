/*eslint no-unused-vars: ["error", { "vars": "local" }]*/

import "./main.scss"
import "core-js/modules/es.promise"
import "intersection-observer"
import "blissfuljs/bliss.shy.js"
import "blissfuljs/bliss._.js"
import "lazysizes"

const $ = window.Bliss
const $$ = $.$
import _throttle from "lodash/throttle"

import { initAgenda } from "./js/agenda"
import { Gallery } from "./js/gallery"
// import throttle from "lodash.throttle"
import { initIntersectionObserver } from "./js/intersection"
import { Masonry } from "./js/masonry"
import { Navigation } from "./js/navigation"
import { OverlayHandler } from "./js/overlayHandler"

// import {Gallery} from '../../../../bliss-gallery'
// import KeywordOrdering from "js/keywordOrdering"
// import Cookies from "js-cookie"
// import {ToggleElements} from './js/toggleElements'

require("smoothscroll-polyfill").polyfill()
// const imagesLoaded = require("imagesloaded")

$.ready().then(() => {
  initIntersectionObserver()

  $$("#header__navigation-cb").forEach((cb) => (cb.checked = false))
  $$("#header__navigation-cb")._.addEventListener("change", (event) => {
    document.body.classList.toggle("nav-is-visible", event.target.checked)
  })

  // Overlay Handler
  const overlay = $("[data-overlay]")
  if (overlay) {
    new OverlayHandler(overlay)
  }

  // Mobile Nav Toggle
  new Navigation()

  const moodboardSlider = $$(".moodboard__slider")
  if (moodboardSlider) {
    moodboardSlider.forEach((element) => {
      const moodboard = new Gallery(element, {
        interval: 7000,
      })

      window.addEventListener("load", () => {
        moodboard._setWidthsAndPositions()
      })
    })
  }

  const scrolledThreshold = $(".sticky-header").offsetHeight - 25
  let lastPageYOffset = 0

  window.addEventListener(
    "scroll",
    _throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
        if (window.pageYOffset > lastPageYOffset) {
          document.body.classList.remove("scrolled--up")
          document.body.classList.add("scrolled--down")
        } else {
          document.body.classList.add("scrolled--up")
          document.body.classList.remove("scrolled--down")
        }
      } else {
        document.body.classList.remove("scrolled")
        document.body.classList.remove("scrolled--up")
        document.body.classList.remove("scrolled--down")
      }

      lastPageYOffset = window.pageYOffset
    }, 100),
  )

  // Masonry
  $$("[data-masonry]").forEach(
    (el) => new Masonry(el, el.getAttribute("data-masonry")),
  )

  initAgenda()

  /*
   let cookiesAccept = $(".cookies-close span")

   cookiesAccept.addEventListener("click", e => {
   e.preventDefault()
   Cookies.set("cookies-accept", true, {expires: 20})
   document.body.classList.remove("cookies-unaccepted")
   })

   if (Cookies.get("cookies-accept") == undefined) {
   document.body.classList.add("cookies-unaccepted")
   }
   */

  // Anchor scrolling

  $$("[data-anchor-scrolling]").forEach((el) => {
    const links = $$("a", el)

    links.forEach((link) => {
      const href = link.getAttribute("href")

      link.addEventListener("click", (e) => {
        e.preventDefault()
        const target = $(href)

        if (target) {
          target.scrollIntoView({ behavior: "smooth" })
          history.replaceState({}, "", href)
        }
      })
    })
  })
})

window.addEventListener("load", () => {
  const popupTrigger = document.querySelector("#popupTrigger")

  function setCookie(name, value, days) {
    let expires = ""
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`
  }

  function getCookie(name) {
    const nameEQ = `${name}=`
    const ca = document.cookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === " ") c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  }

  if (popupTrigger) {
    // let mql = window.matchMedia("(min-width: 600px)")
    //
    // if (mql.matches) {
    Paperform.popup("8ocg7pyt") // eslint-disable-line
    // }

    const observer = new IntersectionObserver((entry) => {
      const popupClose = document.querySelector(".Paperform__popupclose")
      const popupContent = document.querySelector(".Paperform__popupcontent")

      popupContent.appendChild(popupClose) // eslint-disable-line
      document.body.classList.remove("Paperform--locked")

      const cookie = getCookie("paperformSeen")
      if (entry[0].intersectionRatio > 0 && cookie !== "ok") {
        setCookie("paperformSeen", "ok", 3)
        document
          .querySelector(".Paperform__popupwrapper")
          .classList.add("active")
      }
    })

    observer.observe(popupTrigger)
  }
})
