const { $, $$ } = window // eslint-disable-line

export function initIntersectionObserver() {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      document.body.classList.toggle(
        entry.target.dataset.intersectionBodyClass,
        entry.isIntersecting,
      )
    })
  })
  $$("[data-intersection-body-class]").forEach((element) =>
    observer.observe(element),
  )
}
