const $ = window.Bliss
const $$ = $.$

export class OverlayHandler {
  constructor(el) {
    this.el = el
    this.openButton = $$("[data-overlay-open]", el)

    this.open = false
    this.overlayLevel = null
    this.addListeners()
  }

  addListeners = () => {
    this.openButton.forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault()
        if (!this.open) {
          document.body.classList.add("overlay--open")
          document.body.classList.add(`overlay--${el.dataset.overlayLevel}`)
          this.overlayLevel = el.dataset.overlayLevel
          this.open = true
        } else if (
          el.dataset.overlayLevel === "info" &&
          this.overlayLevel === "menu"
        ) {
          document.body.classList.remove("overlay--menu")
          document.body.classList.add(`overlay--${el.dataset.overlayLevel}`)
          this.overlayLevel = el.dataset.overlayLevel
        } else {
          document.body.classList.remove("overlay--open")
          document.body.classList.remove(`overlay--${this.overlayLevel}`)
          this.open = false
        }
      })
    })

    const appearMenu = document.querySelector("[data-appear-menu]")
    const triggerMenu = document.querySelector("[data-trigger-menu]")

    const observerOptions = {
      threshold: 0,
      rootMargin: "0px",
    }

    if (appearMenu) {
      const observer = new IntersectionObserver((triggerMenu) => {
        if (triggerMenu[0].intersectionRatio > 0) {
          appearMenu.classList.remove("active")
        } else {
          appearMenu.classList.add("active")
          document.body.classList.remove("overlay--open")
          document.body.classList.remove(`overlay--${this.overlayLevel}`)
          this.open = false
        }
      }, observerOptions)

      observer.observe(triggerMenu)
    }
  }
}
